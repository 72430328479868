"use client";

import clsx from "clsx";
import Image from "next/image";

import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

interface CenteredCardInterface {
  title: string;
  subTitle?: string;
  centered: boolean;
  items: CardItemInterface[];
}

export interface CardItemInterface {
  image?: {
    data: StrapiImage;
  };
  title: string;
  subTitle: string;
}

export const CenteredCard = ({ ...component }: CenteredCardInterface) => {
  return (
    <div
      className={clsx("my-10", {
        "text-center": component.centered,
      })}
    >
      <p className={clsx("text-3xl font-bold text-kodi-blue", { "mb-10": !component.subTitle })}>{component.title}</p>
      {component.subTitle && <p className="text-lg text-kodi-gray-600 mb-10">{component.subTitle}</p>}

      <div
        className={clsx("grid grid-cols-12 gap-5 lg:gap-10", {
          "text-left": !component.centered,
        })}
      >
        {component.items.map((item, index) => (
          <CardItem {...item} key={index} />
        ))}
      </div>
    </div>
  );
};

const CardItem = ({ ...item }: CardItemInterface) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <Image
        width={100}
        height={100}
        src={getImageUrl(item.image)}
        priority={true}
        quality={80}
        className="w-full mb-7 max-w-[22rem] mx-auto"
        alt={item.image?.data.attributes.name ?? "preview picture"}
      />

      <p className="font-medium text-kodi-blue mb-2 text-xl">{item.title}</p>
      <p className="font-medium">{item.subTitle}</p>
    </div>
  );
};
