import React from "react";

import Modal from "@/components/Modal";

export const VideoModal = ({ isOpen, onClose, children }: { isOpen: boolean; onClose: () => void; children: React.ReactNode }) => {
  return (
    <Modal isOpen={isOpen} close={onClose} size={"extra-large"} className="!p-0" backdropClasses="bg-black bg-opacity-75">
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
