"use client";

import Image from "next/image";
import React from "react";

import { VideoPreview, VideoPreviewInterface } from "@/components/(company)/Preview/VideoPreview";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";
import { ImageInterface } from "@/interfaces/StrapiInterfaces";

interface VideoImageSectionInterface {
  video: VideoPreviewInterface;
  image: ImageInterface;
}

export const VideoImageSection = ({ ...component }: VideoImageSectionInterface) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <div className="max-w-[1450px] mx-auto my-10">
      <div className="grid grid-cols-12 gap-5 lg:gap-10">
        <div className="col-span-12 lg:col-span-6">
          <Image
            width={1000}
            height={1000}
            className="w-full h-full object-contain object-center"
            src={getImageUrl(component.image)}
            priority={true}
            quality={80}
            alt={component.image?.data?.attributes.name ?? "section image"}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <VideoPreview {...component.video} />
        </div>
      </div>
    </div>
  );
};
