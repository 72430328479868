"use client";
import clsx from "clsx";
import Image from "next/image";
import React, { useEffect, useState } from "react";

import { handleCarouselIndex } from "@/components/Carousel";
import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

interface CompanySlider {
  slides: CompanySlide[];
}

export interface CompanySlide {
  image: {
    data: StrapiImage;
  };
  title: string;
}

export const CompanySlider = ({ ...component }: CompanySlider) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const slide = component.slides[slideIndex];

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((index: number) => handleCarouselIndex(true, index, component.slides.length));
    }, 5000);

    return () => clearInterval(interval);
  }, [component.slides]);

  if (!slide) {
    return <></>;
  }

  return (
    <div className="w-full mb-5 max-w-[1450px] mx-auto">
      <Image
        width={1000}
        height={1000}
        className="w-full max-h-[611px] object-contain"
        src={getImageUrl(slide.image)}
        priority={true}
        quality={80}
        alt={slide.image.data.attributes.name ?? "company slide"}
      />

      <div className="flex justify-center">
        {component.slides.map((slide, index) => {
          const isActive = slideIndex === index;

          return (
            <>
              <div
                className={clsx("px-4 py-5 w-full text-center cursor-pointer text-kodi-blue text-lg md:block hidden", {
                  "border-t-4 border-kodi-magenta font-medium": isActive,
                  "border-t-4 border-white": !isActive,
                })}
                key={index}
                onClick={() => setSlideIndex(index)}
              >
                <p>{slide.title}</p>
              </div>
              <div className="px-1 py-2 md:hidden block">
                <div className="manual-carousel-slides">
                  <label
                    key={index}
                    htmlFor={`slideLabel${index}`}
                    onClick={() => setSlideIndex(index)}
                    className={clsx("manual-btn", { "!bg-kodi-magenta": isActive })}
                  ></label>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
