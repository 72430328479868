"use client";

import Image from "next/image";
import Link from "next/link";
import React from "react";

import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

interface CatalogInterface {
  searchParams?: unknown;
  file?: {
    data: {
      id: string;
      attributes: {
        url: string;
      };
    };
  };
  preview?: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
}

export const Catalog = ({ ...component }: CatalogInterface) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <div className="max-w-[1500px] mx-auto py-5 px-5 lg:px-8">
      <div className="px-20 my-10">
        <Link href={`/catalog/${component?.file?.data?.id}`} target="_blank">
          <Image
            width={1000}
            height={1000}
            className="w-full mx-auto"
            src={getImageUrl(component?.preview)}
            priority={true}
            quality={80}
            alt={"catalog preview"}
          />
        </Link>
      </div>
    </div>
  );
};
