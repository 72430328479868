"use client";

import clsx from "clsx";
import { useState } from "react";

import { Collapse, CollapseInterface } from "@/components/(company)/Collapse";

interface QuestionInterface {
  title?: string;
  subTitle?: string;
  isCentered?: boolean;
  collapses: CollapseInterface[];
}

export const Questions = ({ collapses, title, subTitle, isCentered }: QuestionInterface) => {
  const [selectedCollapse, setSelectedCollapse] = useState<CollapseInterface | null>(null);

  return (
    <div className="my-10">
      <div className={clsx("", { "text-center": isCentered, "mb-10": title || subTitle })}>
        {title ? <p className={clsx("font-bold text-2xl lg:text-3xl text-kodi-blue")}>{title}</p> : null}
        {subTitle ? <div className={clsx("", { "mt-4": title })} dangerouslySetInnerHTML={{ __html: subTitle }} /> : null}
      </div>

      {collapses.map((collapse, index) => {
        return (
          <Collapse
            className={`border ${collapses.length < index + 1 ? "border-b-0" : "border-b"} py-2 pr-3 border-gray-200 focus:ring-4 focus:ring-gray-200 hover:bg-gray-100`}
            titleClassName="text-black font-bold text-xl lg:text-2xl"
            isSelected={selectedCollapse === collapse}
            onClick={() => setSelectedCollapse(selectedCollapse === collapse ? null : collapse)}
            key={index}
            {...collapse}
          />
        );
      })}
    </div>
  );
};
