"use client";

import clsx from "clsx";
import { ImageTextCol, ImageTextColProps } from "src/components/(company)/Block/ImageTextCol";

import { colSpanMap, NumberOfCols } from "@/components/(company)/Util/ColSpanMap";

interface ImageTextGridProps {
  title?: string;
  subTitle?: string;
  isContentCentered: boolean;
  areTitlesCentered: boolean;
  numberOfInitialCols: number;
  gridEntries: ImageTextColProps[];
}

export const ImageTextColGrid = ({ ...component }: ImageTextGridProps) => {
  return (
    <div className="flex justify-center mb-10 mt-14">
      <div className="w-full max-w-[1500px] lg:px-8 2xl:px-0">
        <div
          className={clsx(
            "flex flex-col mb-4",
            component.areTitlesCentered ? "items-center" : "items-start",
            component.title || component.subTitle ? "mb-5" : "",
          )}
        >
          {component.title && <span className="text-2xl font-medium text-kodi-blue">{component.title}</span>}
          {component.subTitle && <div dangerouslySetInnerHTML={{ __html: component.subTitle }} />}
        </div>
        <div className="grid grid-cols-12 gap-x-0 gap-y-10 sm:gap-x-4 sm:gap-y-6 md:gap-6 lg:gap-x-10 md:gap-y-0">
          {component.gridEntries.map((entry, index) => {
            return (
              <div className={clsx(colSpanMap[component.numberOfInitialCols as NumberOfCols])} key={index}>
                <ImageTextCol title={entry.title} image={entry.image} text={entry.text} isContentCentered={component.isContentCentered} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
