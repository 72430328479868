"use client";

import clsx from "clsx";
import { useState } from "react";

interface TitleTextInterface {
  title?: string;
  subTitle?: string;
  text: string;
  isCentered?: boolean;
}

export const TitleText = ({ ...component }: TitleTextInterface) => {
  return (
    <div className="my-7">
      {component.title || component.subTitle ? (
        <div className={clsx("mb-5", { "text-center": component.isCentered })}>
          {component.title && (
            <p
              className={clsx("text-kodi-blue font-medium text-2xl mb-4", {
                "mb-7": component.subTitle?.length,
                "mb-3": !component.subTitle?.length,
              })}
            >
              {component.title}
            </p>
          )}
          {component.subTitle && <div className="text-kodi-blue font-medium text-2xl mb-3" dangerouslySetInnerHTML={{ __html: component.subTitle }}></div>}
        </div>
      ) : null}
      <ShowMoreText text={component.text} />
    </div>
  );
};

const ShowMoreText = ({ text }: { text: string }) => {
  const [isReadMore, setReadMore] = useState<boolean>(false);

  if (isReadMore || text.length < 600) {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: text }} className="text-kodi-gray-600" />

        {text.length >= 600 ? (
          <button className="text-kodi-blue mt-5" onClick={() => setReadMore(!isReadMore)}>
            Weniger anzeigen
          </button>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <div className="relative">
        <div className="absolute inset-0 z-50 bg-gradient-to-b from-transparent to-white"></div>

        <div dangerouslySetInnerHTML={{ __html: text.substring(0, 600) }} className="relative z-20 text-kodi-gray-600" />
      </div>

      {text.length >= 600 ? (
        <button className="text-kodi-blue mt-5" onClick={() => setReadMore(!isReadMore)}>
          Mehr anzeigen
        </button>
      ) : null}
    </div>
  );
};
