"use client";

import clsx from "clsx";
import Image from "next/image";
import React, { useState } from "react";

import { VideoModal } from "@/components/(company)/Preview/VideoModal";
import { PlayCircleIcon } from "@/components/Icon/Play/PlayCircleIcon";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";
import { ImageInterface } from "@/interfaces/StrapiInterfaces";

export interface VideoPreviewInterface {
  thumbnail?: ImageInterface;
  videoLink: string;
}

export const VideoPreview = ({ ...component }: VideoPreviewInterface) => {
  return (
    <div className="max-w-[1450px] mx-auto my-10">
      {!component.thumbnail?.data?.attributes.url ? <EmbeddedVideo modalPreview={false} videoLink={component.videoLink} /> : <Thumbnail {...component} />}
    </div>
  );
};

const Thumbnail = ({ thumbnail, videoLink }: VideoPreviewInterface) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });
  const [isShow, setShow] = useState<boolean>(false);

  if (!thumbnail?.data?.attributes.url) {
    return <EmbeddedVideo videoLink={videoLink} modalPreview={false} />;
  }

  return (
    <>
      <div className="relative">
        <Image
          width={100}
          height={100}
          className="w-full h-full object-contain object-center cursor-pointer"
          src={getImageUrl(thumbnail)}
          priority={true}
          quality={80}
          onClick={() => setShow(true)}
          alt={thumbnail?.data.attributes.name ?? "video thumbnail"}
        />

        <div className="absolute inset-0 flex items-center justify-center text-center pointer-events-none">
          <PlayCircleIcon className="w-12 h-12" />
        </div>
      </div>

      <VideoModal isOpen={isShow} onClose={() => setShow(false)}>
        <EmbeddedVideo videoLink={videoLink} modalPreview={true} />
      </VideoModal>
    </>
  );
};

const EmbeddedVideo = ({ videoLink, modalPreview = false }: { videoLink: string; modalPreview: boolean }) => {
  return (
    <iframe
      className={clsx("w-full h-full", { "min-h-[35rem]": !modalPreview })}
      src={videoLink}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );
};
