"use client";

import clsx from "clsx";
import { useState } from "react";

import { InformationCard, InformationCardInterface } from "@/components/(company)/Information/Card";
import { ImageDetail, ModalSettingsInterface } from "@/components/(company)/Slider/component/ItemImageSlider/Detail";
import { ItemImageInterface } from "@/components/(company)/Slider/ItemImageSlider";
import { ColsNumbers, colSpans } from "@/components/(company)/Util/ColSpanMap";

interface InformationRowInterface {
  title?: string;
  subtitle?: string;
  isCentered?: boolean;
  isMobileCentered?: boolean;
  isImageModal?: boolean;
  columns: ColumnEnum;
  entries: InformationCardInterface[];
  modalSettings?: ModalSettingsInterface;
}

export enum ColumnEnum {
  "one",
  "two",
  "three",
  "four",
  "six",
  "twelfth",
}

const titleCentered = (isMobileCentered?: boolean, isCentered?: boolean) => {
  if (isMobileCentered && isCentered) {
    return "justify-center items-center";
  }

  if (!isCentered && isMobileCentered) {
    return "lg:items-start lg:justify-start items-center justify-center";
  }

  if (isCentered && !isMobileCentered) {
    return "lg:items-center lg:justify-center justify-start items-start";
  }

  return "justify-start items-start";
};

export const InformationRow = ({ ...component }: InformationRowInterface) => {
  const [currentImage, setCurrentImage] = useState<ItemImageInterface | null>(null);

  return (
    <>
      <div className="flex justify-center mb-12 mt-10">
        <div className="w-full max-w-[1500px] lg:px-8 2xl:px-0">
          <div className={clsx(`flex flex-col mb-10 ${titleCentered(component.isMobileCentered, component.isCentered)}`)}>
            {component.title && <span className="text-3xl font-medium text-kodi-blue">{component.title}</span>}
            {component.subtitle && <span className="text-lg text-kodi-gray-600 mt-3">{component.subtitle}</span>}
          </div>

          <div className="grid grid-cols-12 justify-items-center md:justify-items-start gap-x-0 gap-y-10 sm:gap-x-4 sm:gap-y-6 md:gap-6 lg:gap-x-7 lg:gap-y-16 md:gap-y-0">
            {component.entries.map((entry, index) => {
              return (
                <div key={index} className={`${clsx(colSpans[ColumnEnum[component.columns] as unknown as ColsNumbers])} w-full`}>
                  <InformationCard {...entry} columns={component.columns} onClick={() => setCurrentImage({ image: entry.image })} />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {component.isImageModal && (
        <ImageDetail isOpen={!!currentImage} onClose={() => setCurrentImage(null)} item={prepareItem(currentImage, component.modalSettings)} />
      )}
    </>
  );
};

const prepareItem = (item?: ItemImageInterface | null, modalSettings?: ModalSettingsInterface): ItemImageInterface & ModalSettingsInterface => {
  return {
    ...item,
    ...modalSettings,
  } as ItemImageInterface & ModalSettingsInterface;
};
