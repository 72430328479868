"use client";

import Link from "next/link";

interface ButtonInterface {
  name: string;
  link: string;
  target: "_blank" | "_self" | "_parent" | "_top";
  onClick?: () => void;
}

export const Button = ({ ...component }: ButtonInterface) => {
  return (
    <div className="my-2">
      <Link
        onClick={component.onClick}
        href={component.link ?? "#!"}
        target={component.target}
        className="bg-transparent border-[2px] transition-all duration-150 rounded-md border-kodi-gray-600 hover:border-kodi-blue text-kodi-blue font-medium hover:bg-kodi-blue hover:text-white py-3 px-7 text-lg"
      >
        {component.name}
      </Link>
    </div>
  );
};
