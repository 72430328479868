import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React, { Fragment } from "react";

import { ModalProvider, useModal } from "@/lib/contexts/modal-context";

type ModalProps = {
  isOpen: boolean;
  close: () => void;
  size?: "small" | "medium" | "large" | "extra-large" | "extra-2large";
  className?: string;
  backdropClasses?: string;
  footerText?: string;
};

const ModalComponent: React.FC<React.PropsWithChildren<ModalProps>> = ({
  isOpen,
  close,
  size = "medium",
  children,
  className,
  backdropClasses,
  footerText,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[75]" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx("fixed inset-0 backdrop-blur-sm", { "bg-gray-700 bg-opacity-75": !backdropClasses, [backdropClasses as string]: backdropClasses })}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  className,
                  "flex flex-col justify-start w-full h-full transform overflow-auto bg-white p-10 text-left align-middle shadow-xl transition-all max-h-[75vh]",
                  {
                    "max-w-md": size === "small",
                    "max-w-xl": size === "medium",
                    "max-w-3xl": size === "large",
                    "max-w-6xl": size === "extra-large",
                    "max-w-full mx-10 !justify-center": size === "extra-2large",
                  },
                )}
              >
                <ModalProvider close={close}>{children}</ModalProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>

          {footerText ? (
            <Transition.Child className="absolute bottom-0 bg-gradient-to-b from-transparent to-black py-5 w-full text-center">
              <p className="text-xl text-white">{footerText}</p>
            </Transition.Child>
          ) : null}
        </div>
      </Dialog>
    </Transition>
  );
};

const Title: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { close } = useModal();

  return (
    <Dialog.Title className="flex items-center justify-between">
      <div className="text-large-semi">{children}</div>
      <div>
        <button onClick={close}>
          <XMarkIcon fontSize={20} />
        </button>
      </div>
    </Dialog.Title>
  );
};

const Description: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Dialog.Description className="flex text-small-regular text-gray-700 items-center justify-center pt-2 pb-4 h-full">{children}</Dialog.Description>;
};

const Body: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="flex-1">{children}</div>;
};

const Footer: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="flex items-center justify-end gap-x-4">{children}</div>;
};

const Modal = ModalComponent as typeof ModalComponent & { Title: typeof Title; Description: typeof Description; Body: typeof Body; Footer: typeof Footer };
Modal.Title = Title;
Modal.Description = Description;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
