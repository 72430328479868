"use client";

import clsx from "clsx";
import Link from "next/link";

interface SocialmediaSectionInterface {
  title: string;
  items: SocialmediaItemInterface[];
}

interface SocialmediaItemInterface {
  title: string;
  link: string;
}

export const SocialmediaSection = ({ ...component }: SocialmediaSectionInterface) => {
  return (
    <div className="max-w-[1450px] mx-auto my-10 text-center">
      <p className="text-kodi-blue font-bold p-20 text-2xl lg:text-3xl bg-gray-100">{component.title}</p>
      {component.items.map((item, index) => (
        <SocialmediaItem {...item} index={index} key={index} />
      ))}
    </div>
  );
};

const SocialmediaItem = ({ title, link, index }: SocialmediaItemInterface & { index: number }) => {
  return (
    <div
      className={clsx("p-10", {
        "bg-white": index % 2 === 0,
        "bg-gray-100": index % 2 !== 0,
      })}
    >
      <Link href={link} className="underline text-kodi-blue font-medium">
        {title}
      </Link>
    </div>
  );
};
