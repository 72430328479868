"use client";

import clsx from "clsx";
import Image from "next/image";
import { useState } from "react";

import { ImageDetail } from "@/components/(company)/Slider/component/ItemImageSlider/Detail";
import { ContainerTypeEnum, ScrollContainer, ScrollItem } from "@/components/Scroll/Container";
import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

interface ItemImageSliderInterface {
  title: string;
  items: ItemImageInterface[];
}

export interface ItemImageInterface {
  image: {
    data: StrapiImage;
  };
  title?: string;
}

export const ItemImageSlider = ({ ...component }: ItemImageSliderInterface) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<ItemImageInterface | null>(component.items.at(0) ?? null);

  const handleClick = (item: ItemImageInterface) => {
    setCurrentItem(item);
    setOpen(true);
  };

  return (
    <div className="my-14">
      <p className="text-3xl font-medium text-kodi-blue mb-7">{component.title}</p>

      <div className="relative w-full overflow-y-auto scrollbar-width px-2 md:px-0 md:pr-2 py-2 2xl:py-0">
        <ScrollContainer type={ContainerTypeEnum.company}>
          <ScrollItems items={component.items} onClick={handleClick} />
        </ScrollContainer>
      </div>

      <ImageDetail item={currentItem} isOpen={isOpen} onClose={() => setOpen(false)} />
    </div>
  );
};

const ScrollItems = ({ items, onClick }: { items: ItemImageInterface[]; onClick: (item: ItemImageInterface) => void }) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <>
      {items.map((item, index) => {
        return (
          <ScrollItem
            key={index}
            className={clsx("min-h-[10rem] min-w-[19rem] md:w-[22rem] lg:h-[15rem] overflow-hidden lg:w-[25rem] bg-gray-100 mr-5 relative")}
          >
            <Image
              width={100}
              height={100}
              onClick={() => onClick(item)}
              className="w-full min-h-full object-contain my-auto object-center cursor-pointer"
              src={getImageUrl(item.image)}
              priority={index < 5}
              quality={80}
              alt={item.image.data.attributes.name ?? "slider image"}
            />
            <div className="absolute bottom-0 w-full bg-white/75 px-5 py-2">
              <span>{item.title}</span>
            </div>
          </ScrollItem>
        );
      })}
    </>
  );
};
