"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";

import { ColumnEnum } from "@/components/(company)/Information";
import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

export interface InformationCardInterface {
  image: {
    data: StrapiImage;
  };
  title: string;
  description?: string;
  link?: string;
  onClick?: () => void;
}

export const InformationCard = ({ ...component }: InformationCardInterface & { columns: ColumnEnum }) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });
  const router = useRouter();

  const handleClick = () => {
    if (component?.link) {
      router.push(component.link ?? "#!");
    }
  };

  return (
    <div className="flex flex-col cursor-pointer h-full justify-between" onClick={() => handleClick()}>
      <div className="text-2xl font-medium text-kodi-blue break-normal">{component.title}</div>
      <Image
        width={600}
        height={332}
        className="my-2 w-full"
        onClick={component.onClick}
        src={getImageUrl(component?.image)}
        priority={true}
        quality={80}
        alt={component?.image?.data?.attributes?.name ?? "Information Preview"}
      />
      <div className="flex-grow mb-3">{component?.description && <div dangerouslySetInnerHTML={{ __html: component.description }} />}</div>
      <div className="mt-auto">{component?.link && <span className="text-kodi-blue underline text-xl">Mehr erfahren</span>}</div>
    </div>
  );
};
