"use client";

import Image from "next/image";

import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

interface ImageGridInterface {
  images: {
    image: {
      data: StrapiImage;
    };
  }[];
}

export const ImageGrid = ({ ...component }: ImageGridInterface) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <div className="grid grid-cols-12 gap-y-5 lg:gap-10 my-5">
      {component.images.map(({ image }, index) => {
        return (
          <div className="col-span-12 lg:col-span-6" key={index}>
            <Image
              src={getImageUrl(image)}
              priority={index < 3}
              quality={80}
              alt={image.data.attributes.name ?? "vision image"}
              width={1000}
              height={1000}
              className="w-full"
            />
          </div>
        );
      })}
    </div>
  );
};
