"use client";

import Image from "next/image";
import Link from "next/link";
import React from "react";

import { ThemeWorld } from "@/components/Slider/Product/components/Types/ThemeWorld";
import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

interface ProductSliderInterface {
  medusa_key?: string;
  link?: string;
  informationText?: string;
  textCardBackground?: {
    data: StrapiImage;
  };
  cardBackground?: string;
  productBackground?: string;
  textColor?: string;
}

export const ProductSlider = ({ ...component }: ProductSliderInterface) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <div className="max-w-[1500px] mx-0 sm:mx-10 md:mx-auto py-5 px-5 lg:px-8 ">
      <div className="grid grid-cols-12 mx-auto" style={{ backgroundColor: `${component.productBackground}` }}>
        <div className="col-span-12 md:col-span-4 relative md:max-w-full md:max-h-full mx-auto my-auto">
          <Link href={component?.link ?? "#!"} className="p-1 lg:p-5 text-center flex items-center h-full justify-center absolute inset-0" />

          {component.textCardBackground && (
            <Image
              src={getImageUrl(component.textCardBackground)}
              priority={true}
              quality={80}
              width={1000}
              className="my-auto"
              height={1000}
              alt={component.textCardBackground?.data?.attributes.name ?? "Product Slider"}
            />
          )}
        </div>
        {component?.informationText && (
          <div className="col-span-12 block md:hidden p-2">
            <p className="font-medium text-center">{component?.informationText}</p>
          </div>
        )}
        <div className="col-span-12 md:col-span-8 flex flex-row items-center" style={{ backgroundColor: component.textColor }}>
          <ThemeWorld type={component?.medusa_key ?? ""} />
        </div>
      </div>
    </div>
  );
};
