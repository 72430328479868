"use client";

import Image from "next/image";
import React from "react";

import { Button } from "@/components/(company)/Button";
import { ItemImageInterface } from "@/components/(company)/Slider/ItemImageSlider";
import Modal from "@/components/Modal";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

export interface ModalSettingsInterface {
  headline?: string;
  subHeadline?: string;
  button?: string;
  buttonLink?: string;
}

export const ImageDetail = ({
  isOpen,
  onClose,
  item,
}: {
  isOpen: boolean;
  onClose: () => void;
  item: (ItemImageInterface & ModalSettingsInterface) | null;
}) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <Modal isOpen={isOpen} close={onClose} size={"extra-2large"} className="!p-0" backdropClasses="bg-black bg-opacity-75" footerText={item?.title}>
      {item?.headline || item?.subHeadline ? (
        <Modal.Title>
          <div className="my-2 mx-5">
            {item?.headline && <p className="text-base text-kodi-gray-600">{item.headline}</p>}
            {item?.subHeadline && <p className="text-xl font-bold text-kodi-blue">{item.subHeadline}</p>}
          </div>
        </Modal.Title>
      ) : null}
      <Modal.Body>
        <Image
          width={100}
          height={100}
          className="w-full h-full object-contain object-center"
          src={getImageUrl(item?.image)}
          priority={true}
          quality={80}
          alt={item?.image?.data.attributes.name ?? "slider image"}
        />
      </Modal.Body>
      <Modal.Footer>
        {item?.button && item?.buttonLink && (
          <div className="flex justify-end m-5">
            <Button name={item.button} link={item.buttonLink} target={"_self"} onClick={onClose} />
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};
