"use client";
import clsx from "clsx";
import Image from "next/image";

import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

interface ImageTextBlockInterface extends TextInterface {
  image?: {
    data: StrapiImage;
  };
  isRightAlign: boolean;
}

interface TextInterface {
  title?: string;
  subTitle?: string;
  text?: string;
}

export const ImageTextBlock = ({ ...component }: ImageTextBlockInterface) => {
  return (
    <div className="grid grid-cols-12 gap-0 gap-y-5 lg:gap-10 my-5">
      <div className="col-span-12 lg:col-span-6 my-auto">{component.isRightAlign ? <ImageComponent {...component} /> : <TextComponent {...component} />}</div>
      <div className="col-span-12 lg:col-span-6 my-auto">{component.isRightAlign ? <TextComponent {...component} /> : <ImageComponent {...component} />}</div>
    </div>
  );
};

const TextComponent = ({ title, text, subTitle }: TextInterface) => {
  return (
    <>
      {title && <p className={clsx("text-2xl font-bold text-kodi-blue", { "mb-5": !subTitle, "mb-7": subTitle })}>{title}</p>}
      {subTitle && <p className="text-2xl font-bold text-kodi-blue mb-5">{subTitle}</p>}
      <div className="text-kodi-gray-600" dangerouslySetInnerHTML={{ __html: text ?? "" }} />
    </>
  );
};

const ImageComponent = ({ image }: { image?: { data: StrapiImage } }) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <Image
      width={100}
      height={100}
      className="w-full"
      src={getImageUrl(image)}
      priority={true}
      quality={80}
      alt={image?.data?.attributes.name ?? "Image Preview"}
    />
  );
};
