"use client";
import Image from "next/image";

import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

export interface StatisticWrapperInterface {
  image?: {
    data: StrapiImage;
  };
  title: string;
  subTitle: string;
}

export const StatisticWrapper = ({ ...component }: { items: StatisticWrapperInterface[] }) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <div className="flex justify-between flex-wrap bg-ch21-gray py-5 my-7">
      {component.items?.map((item, index) => {
        return (
          <div className="mx-auto p-5" key={index}>
            <Image
              width={100}
              height={100}
              className="w-[10rem] mx-auto"
              src={getImageUrl(item.image)}
              priority={index < 2}
              quality={80}
              alt={item.image?.data.attributes.name ?? "statistic image"}
            />

            <div className="text-center mt-10">
              <p className="font-medium text-kodi-blue text-2xl">{item.title}</p>
              {item.subTitle ? <p className="text-kodi-gray-600 mt-3 text-lg">{item.subTitle}</p> : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};
