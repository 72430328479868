"use client";

import clsx from "clsx";
import Image from "next/image";

import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

export interface ImageTextColProps {
  title?: string;
  image?: {
    data: StrapiImage;
  };
  text?: string;
  isContentCentered: boolean;
}

export const ImageTextCol = ({ ...component }: ImageTextColProps) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <div className={clsx("flex flex-col my-7 max-w-[400px]", component.isContentCentered ? "items-center" : "items-start")}>
      <Image
        src={getImageUrl(component.image)}
        priority={true}
        quality={80}
        width={512}
        height={288}
        className="w-full"
        alt={component.image?.data?.attributes.name ?? "Image Preview"}
      />
      {component.title && <span className="text-xl font-bold mt-2 text-kodi-blue">{component.title ?? ""}</span>}
      {component.text && (
        <div
          className={clsx("text-kodi-gray-600 text-lg mt-3", component.isContentCentered ? "text-center" : "text-left")}
          dangerouslySetInnerHTML={{ __html: component.text ?? "" }}
        />
      )}
    </div>
  );
};
