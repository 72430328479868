"use client";

import Image from "next/image";
import Link from "next/link";

import { StrapiImage } from "@/data/strapi";
import { useImageSrcSet } from "@/hook/ImageSrcSetHook";

export interface ContactPersonCardProps {
  image: {
    data: StrapiImage;
  };
  name: string;
  jobTitle: string;
  email: string;
  phoneNumber: string;
}

export const ContactPersonCard = ({ ...component }: ContactPersonCardProps) => {
  const { getImageUrl } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <div className="flex flex-col my-7">
      <Image
        width={460}
        height={460}
        className={""}
        src={getImageUrl(component.image)}
        priority={true}
        quality={80}
        alt={component?.image?.data?.attributes?.name ?? "Information Preview"}
      />
      <div className="flex flex-col mt-5 text-lg text-kodi-gray-600 max-w-[460px] -space-y-1">
        <span className="font-bold">{component.name}</span>
        <span className="italic">{component.jobTitle}</span>
        <div className="flex flex-row flex-wrap">
          <span className="text-nowrap">E-Mail:</span>
          <Link className="ml-1 text-kodi-blue underline" href={`mailto:${component.email}`}>
            {component.email}
          </Link>
        </div>
        <span>Tel.: {component.phoneNumber}</span>
      </div>
    </div>
  );
};
